.tag {
  display: flex;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  padding: 0 10px;
  overflow: hidden;

  background-color: #ffddca;
  border-radius: 50px;

  &.middle {
    zoom: 1.3;
  }
}

.image {
  width: auto;
  height: 16px;
}

.text {
  display: flex;
  flex-direction: column;

  color: var(--color-black);
  font-weight: 500;
  text-align: center;

  transform: translateY(-25%);

  &.withAnimation {
    animation: animation 6s infinite linear;
  }
}

.text1 {
  height: 32px;

  font-weight: 500;

  font-size: 12px;
  line-height: 32px;
}

.text2 {
  height: 32px;

  font-weight: 500;

  font-size: 12px;
  line-height: 32px;
}

@keyframes animation {
  0% {
    transform: translateY(-50%);
  }
  /* сдвиг */
  2% {
    transform: translateY(-25%);
  }
  /* ожидание */
  48% {
    transform: translateY(-25%);
  }
  /* сдвиг */
  51% {
    transform: translateY(25%);
  }
  /* ожидание */
  98% {
    transform: translateY(25%);
  }
  /* сдвиг */
  100% {
    transform: translateY(50%);
  }
}
