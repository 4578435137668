.wrapper {
  width: 100%;
  max-width: 1920px;
  margin-inline: auto;

  line-height: 1.5;
}

.inContainer {
  --padding: var(--space-xxb);

  .textContainer,
  .slider,
  .grid,
  .tabs {
    padding-inline: var(--padding);
  }

  .arrows {
    padding-right: var(--padding);
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  max-width: 1920px;
  margin-inline: auto;
  margin-bottom: var(--space-l);

  column-gap: var(--space-n);

  &.withTabs {
    grid-template-columns: 1fr;
  }
}

.title {
  margin: 0;

  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);

  &.enlarged {
    font-size: var(--size-b);
  }
}

.description {
  composes: averageText from 'containers';

  margin-top: var(--space-m);

  font-size: var(--size-m);

  &.enlarged {
    font-size: var(--size-l);
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-m);
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
}

.tabs {
  box-sizing: border-box;
  max-width: 100%;
  margin-top: var(--space-n);
  overflow: hidden;
}

.arrows {
  box-sizing: border-box;
  margin-left: auto;
}

.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --columns-count: 4;
  --space-between: 0px;
}

.gallery {
  flex-shrink: 1;
  width: 100%;
  height: 100%;
}

.sliderSlide {
  --width: auto;
}

.defaultSizes {
  --columns-count: 4;
  --space-between: var(--space-l);
}

.defaultGap {
  --space-between: var(--space-l);
}

.defaultSizesSliderSlide,
.flexibleSliderSlide {
  --width: calc(
    100% / var(--columns-count) - var(--space-between) / var(--columns-count) *
      (var(--columns-count) - 1)
  );
}

.sliderSlide {
  width: var(--width);
  margin-right: var(--space-between);

  &:last-child {
    margin-right: 0;
  }

  &.top {
    margin-bottom: auto;
  }

  &.center {
    margin-block: auto;
  }

  &.bottom {
    margin-top: auto;
  }
}

.fullWidth {
  --width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--columns-count), 1fr);
  gap: var(--space-between);
}

.gridSlide {
  width: 100%;
}

@media (--desktop-m) {
  .inContainer {
    --padding: var(--space-xxl);
  }

  .title {
    font-size: var(--size-xl);

    &.enlarged {
      font-size: var(--size-xxl);
    }
  }

  .description {
    font-size: var(--size-n);

    &.enlarged {
      font-size: var(--size-m);
    }
  }

  .defaultSizes {
    --columns-count: 3;
  }
}

@media (--desktop) {
  .defaultSizes,
  .defaultGap {
    --space-between: var(--space-n);
  }
}

@media (--mobile-m) {
  .inContainer {
    --padding: var(--space-s);
  }

  .title {
    font-size: var(--size-l);

    &.enlarged {
      font-size: var(--size-xl);
    }
  }

  .description {
    margin-top: var(--space-s);

    &.enlarged {
      font-size: var(--size-n);
    }
  }

  .tabs {
    margin-top: var(--space-s);
  }

  .defaultSizes {
    --columns-count: 2;
  }

  .defaultSizesSliderSlide {
    --width: 310px;
  }
}

@media (--mobile-s) {
  .defaultSizes {
    --columns-count: 1;
  }
}

@media (--mobile-xxs) {
  .defaultSizesSliderSlide {
    --width: 260px;
  }
}
