.creditTabWrapper {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 7px 16px 7px var(--space-s);

  color: var(--color-dark-gray);

  font-weight: 500;
  font-size: var(--size-xs);
  line-height: 150%;

  background-color: var(--color-white);

  border-radius: 19px;
  cursor: pointer;
}

.image {
  object-fit: contain;
}

.iconImg {
  background-color: transparent;
}

.iconWrapper {
  width: 13px;
  height: 14px;
}

.priceContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 4px 0 2px;

  background-color: #262633;
  border-radius: 9px 2px 2px 9px;
}

.price {
  color: var(--color-white);
}

@media (--mobile-m) {
  .creditTabWrapper {
    display: flex;

    background: var(--color-light-gray);
  }
}
