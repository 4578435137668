.characteristic {
  display: inline-block;
  flex-grow: 0;
  max-width: 100%;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.characteristicValue {
  display: inline;

  font-size: var(--size-s);
  line-height: 1.5;
}

.sizeValue {
  flex-grow: 0;
  max-width: 100%;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.parameter {
  display: flex;
  gap: var(--space-xxs);
  align-items: baseline;
  justify-content: space-between;
  padding-top: var(--space-xs);

  &.themeHardness {
    padding-top: var(--space-xs);
  }
}

.icon {
  display: inline-block;
  margin-right: var(--space-xs);

  vertical-align: middle;
}
