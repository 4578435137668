.subscribed {
  &.bordered {
    border: 1.5px solid var(--color-black);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: var(--color-black);
}

.iconSubscribe {
  width: 50px;
}

@media (--mobile-m) {
  .iconSubscribe {
    width: 40px;
  }
}
