.main {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: min-content;
  padding: 7px var(--space-xs);

  color: var(--color-white);
  font-size: var(--size-xs);
  white-space: nowrap;

  background-color: var(--color-dark-gray);
  border-radius: 99px;
}

.icon {
  margin-right: var(--space-xs);
}
