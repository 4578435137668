.main {
  display: flex;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
}

.text {
  margin-left: var(--space-s);
}

.saleSoon {
  color: var(--color-dark-gray);
}

.zeroOffset {
  margin-left: 0;
}
