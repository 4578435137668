.bottomSheet {
  position: relative;
  z-index: calc(var(--z-index-modal) + 2);

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 143px;

  background-color: var(--color-white);
  border-radius: var(--space-xs) var(--space-xs) 0 0;
  box-shadow: var(--shadow-popup);
  box-shadow: 0px 4px 20px 0px rgba(34, 60, 80, 0.2);
}

.wrap {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--z-index-modal) + 2);
}

.backdrop {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);

  height: 200vh;

  background-color: var(--color-black);
  cursor: pointer;
  opacity: 0.3;
}

.animated {
  transition: transform 200ms;
  transition: all 0.2s ease-in-out 0s;
}

.handle {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;

  cursor: pointer;
}

.line {
  flex-shrink: 0;
  width: 35px;
  height: 5px;

  background-color: var(--color-medium-gray);
  border-radius: var(--space-xxs);
}

.body {
  width: 100%;
}

.expanded .body {
  overflow-y: auto;
  scrollbar-width: none;
}
