.wrapper {
  position: absolute;
  z-index: 2;

  &.active {
    z-index: 4;
  }
}

.pin {
  position: relative;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 34px;
  height: 34px;

  background: var(--color-white);
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  border-radius: 50%;
  cursor: pointer;

  &:before {
    display: block;
    width: 20px;
    height: 20px;

    background-color: var(--color-white);
    border-radius: 50%;
    transform: scale(0.6, 0.6);

    content: '';
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 20px;
    height: 20px;

    background-color: var(--color-white);
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);

    opacity: 0;

    transition: opacity 0.1s ease-in-out;

    content: '';
  }

  &.active {
    &:after {
      opacity: 1;
    }
  }

  &.animated {
    &:before {
      width: 20px;
      height: 20px;

      transform: scale(1, 1);

      animation-name: pulsing;
      animation-duration: 3s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }
  }
}

.popup {
  position: absolute;
  bottom: 0;
  z-index: 2;

  height: fit-content;
  padding-bottom: calc(34px + var(--space-n));

  transform: translateX(calc(-50% + 17px));

  opacity: 0;

  transition: opacity 0.1s ease-in-out 0s;

  &.enter {
    opacity: 0;
  }

  &.enterActive {
    opacity: 1;
  }

  &.enterDone {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exitActive {
    opacity: 0;
  }

  &.exitDone {
    opacity: 0;
  }
}

@keyframes pulsing {
  0% {
    transform: scale(0.6, 0.6);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.6, 0.6);
  }
}

@media (--mobile) {
  .popup {
    position: fixed;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(var(--z-index-fixed-panel) + 2);

    padding-bottom: 0;

    transform: translate3d(0, 0, 0);
  }
}

@media (hover: hover) {
  .pin {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
