.post {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  min-width: 0;
}

.image {
  width: 100%;
  aspect-ratio: '1:1';
}

.author {
  color: var(--color-dark-gray);
}
