.target {
  width: 100%;

  background-color: var(--color-light-gray);
  border-radius: 50%;
  aspect-ratio: '1:1';
}

.container {
  position: absolute;

  display: flex;
  flex-direction: column;
  gap: var(--space-n);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px;

  line-height: 1.5;
  text-align: center;
  inset: 0;
}

.title {
  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);
}

.text {
  font-size: var(--size-n);
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-xm);
  }

  .text {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
}
