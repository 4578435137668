.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0 var(--space-n);

  color: var(--color-white);
  font-weight: 500;
  font-size: var(--size-n);
  font-family: var(--font-main);
  line-height: 1.5;

  background: var(--color-grafit);
  border: 1px solid var(--color-grafit);
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background: var(--color-black);
    border-color: var(--color-black);
  }

  &:active {
    color: var(--color-black);

    background: var(--color-white);
    border-color: var(--color-black);
  }

  &:disabled,
  &[disabled] {
    color: var(--color-dark-gray);

    background: var(--color-medium-gray);
    border-color: var(--color-medium-gray);
    cursor: default;
  }

  &.waiting {
    &:after {
      content: 'Секунду...';
    }

    &.uzLang:after {
      content: 'Bir soniya...';
    }

    &.kkLang:after {
      content: 'Екінші...';
    }
  }

  &:after {
    display: block;

    white-space: nowrap;
    text-overflow: ellipsis;

    content: 'В корзину';
  }

  &.uzLang:after {
    content: 'Savatga';
  }

  &.kkLang:after {
    content: 'Себетке';
  }
}

.hasInCart {
  color: var(--color-black);

  background-color: var(--color-white);

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    border-color: var(--color-black);
  }

  &:before {
    width: 22px;
    height: 22px;
    margin-right: var(--space-xs);

    background-image: url('./icons/check.svg');
    background-repeat: no-repeat;
    background-position: center;

    content: '';
  }

  &:after {
    content: 'Товар в корзине';
  }

  &.uzLang:after {
    content: 'Savatdagi mahsulot';
  }

  &.kkLang:after {
    content: 'Себеттегі тауар';
  }
}

.link {
  display: none;
}

.loader {
  margin-right: var(--space-xs);
}

@media (--desktop-m) {
  .button {
    height: 42px;
  }
}
