.tags {
  display: flex;
  justify-content: flex-end;
}

.tag {
  margin-right: var(--space-xs);

  &:last-child {
    margin-right: 0;
  }
}
