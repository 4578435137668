.noImage {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--color-light-gray);

  aspect-ratio: '13.585:9';

  &.landscape {
    aspect-ratio: '13.585:9';
  }

  &.portrait {
    aspect-ratio: '1:1';
  }
}

.image {
  object-fit: contain;
}
