.title {
  display: inline;
  flex-grow: 0;

  color: #767676;

  font-size: var(--size-s);
  line-height: 1.5;
  white-space: nowrap;
}

.bgc {
  flex-grow: 1;
  height: 2px;

  border-bottom: 1px dashed var(--color-medium-gray);

  &.hidden {
    visibility: hidden;
  }
}
