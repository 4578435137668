.wrapper {
  width: 100%;

  line-height: 1.5;
}

.container {
  composes: main from 'containers';

  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: space-between;
}

.textContainer {
  composes: averageText from 'containers';
}

.title {
  margin: 0;

  font-weight: 500;
  font-size: 30px;
  font-family: var(--font-hero);
}

.description {
  margin-top: 25px;

  font-size: 18px;
}

.gallery {
  --columns-count: 4;
  --space-between: 30px;

  composes: main from 'containers';

  margin-top: 30px;
  overflow: hidden;
}

.slide {
  width: calc(
    100% / var(--columns-count) - var(--space-between) / var(--columns-count) *
      (var(--columns-count) - 1)
  );
  margin-right: var(--space-between);

  &:last-child {
    margin-right: 0;
  }
}

.footerText {
  composes: main from 'containers';

  margin-top: var(--space-n);

  color: var(--color-dark-gray);
}

.footerPhoneLink {
  color: var(--color-dark-gray);
}

@media (--desktop-m) {
  .title {
    font-size: 25px;
  }

  .description {
    font-size: 16px;
  }
}

@media (--desktop) {
  .gallery {
    --columns-count: 3;
    --space-between: 20px;
  }
}

@media (--mobile-m) {
  .title {
    font-size: 22px;
  }

  .description {
    margin-top: 15px;
  }

  .slide {
    width: 350px;
  }
}

@media (--mobile) {
  .slide {
    width: 310px;
  }
}

@media (--mobile-xxs) {
  .slide {
    width: 260px;
  }
}
