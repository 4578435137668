.container {
  display: inline-flex;
  gap: var(--space-xs);
  align-items: center;

  border-radius: 19px;
  cursor: pointer;
}

.iconWrapper {
  width: 12px;
  height: 12px;
}

.image {
  object-fit: contain;
}

.iconImg {
  background-color: transparent;
}

.text {
  display: inline;

  line-height: 150%;

  white-space: nowrap;
}

.viewCatalog {
  gap: var(--space-xxs);
  padding: 7px var(--space-s);

  background: var(--color-white);

  .text {
    color: var(--color-black);
    font-weight: 500;
    font-size: var(--size-xs);
  }

  .text .textGray {
    margin-left: var(--space-xxs);

    color: var(--color-dark-gray);
  }

  .arrow {
    display: none;
  }

  @media (--mobile-xs) {
    .text {
      color: var(--color-dark-gray);
    }

    .text .textGray {
      margin-left: unset;
    }
  }
}

.viewDetail {
  .text {
    font-weight: 400;
    font-size: var(--size-s);
  }

  .textBold {
    font-weight: 500;
  }

  .arrow {
    width: 10px;
    height: 10px;

    margin-left: var(--space-xxs-neg);
  }
}

@media (--mobile-m) {
  .viewCatalog {
    background: var(--color-light-gray);
  }
}
