.section {
}

.head {
  composes: main from 'containers';

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: var(--space-l);
  row-gap: var(--space-l);
}

.headContent {
  max-width: 100%;
}

.description {
  composes: averageText from 'containers';

  margin-top: var(--space-m);
}

.title {
  margin: 0;

  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);
  line-height: 1.5;
  white-space: pre-line;
}

.priceMin {
  margin-left: var(--space-xs);

  font-size: var(--size-m);
  line-height: 1.5;
}

.arrows {
  margin-left: auto;
}

.additional {
  margin-left: var(--space-xxs);
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }
}

@media (--desktop) {
  .title {
    font-size: var(--size-xl);
  }
}

@media (--mobile-m) {
  .head {
    margin-bottom: var(--space-m);
  }

  .title {
    font-size: var(--size-l);
  }

  .arrows {
    display: none;
  }
}

@media (--mobile) {
  .priceMin {
    margin-top: var(--space-xs);
    margin-left: 0;
  }

  .head {
    flex-direction: column;
    align-items: flex-start;
  }

  .additional {
    margin-top: var(--space-n);
    margin-left: 0;
  }
}
