.tag {
  position: relative;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  background: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 50%;

  &.cardView {
    width: 60px;
    height: 60px;
  }

  &:hover {
    .info {
      opacity: 1;
    }
  }
}

.image {
  width: 100%;
  height: 100%;

  background-color: unset;
}

.info {
  position: absolute;
  top: calc(100% + var(--space-xs));
  left: 0;
  z-index: 20;

  padding: var(--space-xs) var(--space-s);

  color: var(--color-dark-gray);
  font-size: 10px;
  line-height: 1.5;
  white-space: nowrap;

  background: var(--color-white);
  border-radius: var(--space-xxs);
  box-shadow: 0px 0px var(--space-n) rgba(0, 0, 0, 0.2);
  transform: translateX(calc(-50% + 30px));
  cursor: default;
  opacity: 0;

  transition: opacity 0.2s;

  &:hover {
    display: none;
  }
}

@media (--desktop-m) {
  .info {
    transform: translateX(calc(-50% + 25px));
  }
}

@media (--desktop) {
  .tag {
    &.cardView {
      width: 50px;
      height: 50px;
    }
  }
}

@media (--mobile) {
  .image {
    border-radius: 50%;
  }
}
